import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";

export default class RequestDemoPage extends React.Component {
  componentDidMount() {
    const marketo = `
    (function(d, script) {
      script = d.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = function() {
        // Load the Marketo form.
        MktoForms2.loadForm("//app-sj31.marketo.com", "217-DHG-997", 1097, function(form) {
          form.onSuccess(function(values, followUpUrl) {
            location.href = "/request-demo-confirmed/";
            return false;
          });
        });
        // Remove the Marketo styles.
        MktoForms2.whenReady(function(form) {
          let marketoBaseStyles = document.getElementById('mktoForms2BaseStyle');
          if (marketoBaseStyles !== null) {
            marketoBaseStyles.remove();
          }
          let marketoThemeStyles = document.getElementById('mktoForms2ThemeStyle');
          if (marketoThemeStyles !== null) {
            marketoThemeStyles.remove();
          }
          // Remove the inline styles from the form and submit button.
          let marketoForm = document.getElementById('mktoForm_1097');
          if (marketoForm !== null) {
            marketoForm.style = '';
          }
          let marketoButtonWrap = document.querySelector('.mktoButtonWrap');
          if (marketoButtonWrap !== null) {
            marketoButtonWrap.style = '';
          }
          // Make the submit button primary.
          let marketoSubmitButton = document.querySelector('.mktoButtonWrap button');
          if (marketoSubmitButton !== null) {
            marketoSubmitButton.classList += ' button primary';
          }
          // Hide the preloader.
          let preloader = document.querySelector('.marketo-loading');
          if (preloader !== null) {
            preloader.remove();
          }
        });
      };
      script.src = '//app-sj31.marketo.com/js/forms2/js/forms2.min.js';
      d.getElementsByTagName('head')[0].appendChild(script);
    })(document);
    `;

    window.eval(marketo);
  }

  render() {
    return (
      <Index>
        <Seo title="Request Demo" />
        <PageHeader extraClasses="auto-height">
          <h1>Request Demo</h1>
        </PageHeader>
        <Container extraContainerClasses="background-light-gray has-marketo-form">
          <p className="marketo-loading text-blue lead align-center">
            Loading, please wait...
          </p>
          <form id="mktoForm_1097" />
        </Container>
      </Index>
    )
  }
}
